import React from 'react'
import Layout from '../../components/layout'
import { Link } from 'gatsby'
import MerchantSignUpButton from '../../components/merchantSignUpButton'

export default function MerchantProfiles () {
  const [overflowVisible, setOverflowVisible] = React.useState(false)
  const [nrmlVisible, setNrmlVisible] = React.useState(false)
  const [nicastroVisible, setNicastroVisible] = React.useState(false)

  return (
    <Layout type='merchant'>
      <section className='mb'>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className='inner'>
              <h1>Merchant profiles</h1>
              <p className='lede'>Our merchants talk about their experience with Trexity.</p>
            </div>
          </div>
        </div>
      </section>

      <section className='well'>
        <div className='row'>
          <div className='col f3'>
            {!nicastroVisible ? (
              <div
                className="merchant-profile-yt"
                style={{ backgroundImage: 'url("/img/merchant-profiles/youtube-nicastro.jpg")' }}
                onClick={() => setNicastroVisible(!nicastroVisible)}
              />
            ) : null}
            {nicastroVisible ? (
              <div className='responsive-video' style={{ borderRadius: 0 }}>
                <iframe
                  src="https://www.youtube.com/embed/NZs_KJyqv0Y?rel=0&amp;modestbranding=1&amp;autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : null}
          </div>
          <div className='col f2'>
            <div className="inner">
              <div className='mb'>
                <img src="/img/merchants/bottega.jpg" alt="La Bottega Nicastro logo" style={{ height: 60, width: 'auto' }} />
              </div>
              <h3>La Bottega Nicastro</h3>
              <p className='mb'>Artisan olive oils and balsamic vinegars, international cheese and deli meats to rustic premium pasta and sauces.</p>
              <Link className='button-link button-link-brand' to='/merchant-profiles/la-bottega-nicastro'>Read more</Link>
            </div>
          </div>
        </div>
      </section>

      <section className='well'>
        <div className='row'>
          <div className='col f3'>
            {!nrmlVisible ? (
              <div
                className="merchant-profile-yt"
                style={{ backgroundImage: 'url("/img/merchant-profiles/youtube-nrml.jpg")' }}
                onClick={() => setNrmlVisible(!nrmlVisible)}
              />
            ) : null}
            {nrmlVisible ? (
              <div className='responsive-video' style={{ borderRadius: 0 }}>
                <iframe
                  src="https://www.youtube.com/embed/fhUuoyZvbts?rel=0&amp;modestbranding=1&amp;autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : null}
          </div>
          <div className='col f2'>
            <div className="inner">
              <div className='mb'>
                <img src="/img/merchants/nrml.jpg" alt="NRML logo" style={{ height: 60, width: 'auto' }} />
              </div>
              <h3>NRML</h3>
              <p className='mb'>NRML is a local Canadian brand streetwear boutique. They specialize in streetwear, sneakers, and apparel.</p>
              <Link className='button-link button-link-brand' to='/merchant-profiles/nrml'>Read more</Link>
            </div>
          </div>
        </div>
      </section>

      <section className='well'>
        <div className='row'>
          <div className='col f3'>
            {!overflowVisible ? (
              <div
                className="merchant-profile-yt"
                style={{ backgroundImage: 'url("/img/merchant-profiles/youtube-overflow.jpg")' }}
                onClick={() => setOverflowVisible(!overflowVisible)}
              />
            ) : null}
            {overflowVisible ? (
              <div className='responsive-video' style={{ borderRadius: 0 }}>
                <iframe
                  src="https://www.youtube.com/embed/8M44JPiDXCY?rel=0&amp;modestbranding=1&amp;autoplay=1"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                />
              </div>
            ) : null}
          </div>
          <div className='col f2'>
            <div className="inner">
              <div className='mb'>
                <img src="/img/merchants/overflow.jpg" alt="Overflow Brewing Company logo" style={{ height: 60, width: 'auto' }} />
              </div>
              <h3>Overflow Brewing Company</h3>
              <p className='mb'>Their name stems from the word flow in general, and that’s a study of happiness and being content with the things you want to do in life.</p>
              <Link className='button-link button-link-brand' to='/merchant-profiles/overflow'>Read more</Link>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className='row justify-center'>
          <div className='col text-center'>
            <div className="inner">
              <h2>Keep the local community</h2>
              <p className='mb'>When people do what they love, they do it better.</p>
              <MerchantSignUpButton />
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}
